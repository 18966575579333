<template>
    <div class="data-visual-container">
        <!-- <h1 style="position: absolute;text-align: center;width: 100%;line-height: 3vw;font-size: 1.8vw;color:#fff;z-index: 9;">{{regionName}}</h1> -->
        <div class="head_nav_banner">
            <div class="nav-top-title">{{ regionName }}</div>
            <a-row>
                <a-col :span="24" style="margin-bottom: 3%;">
                    <a-row :gutter="20" style="position: absolute;right: 7vw;top: -3vw;">
                        <a-col :span="6">
                            <a-select :default-value="currentTime" style="width: 120px" @change="handleChange">
                                <a-select-option v-for="(item, index) in time" :key="index" :value="item.key">
                                    {{ item.name }}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="18">
                            <a-range-picker v-if="currentTime == 'day'" :default-value="dayDate"
                                            @change="onChange('day', $event)"/>
                            <a-month-picker v-if="currentTime == 'month'" :default-value="monthDate"
                                            placeholder="选择月份" @change="onChange('month', $event)"/>
                            <a-date-picker v-if="currentTime == 'year'" v-model="year" format='YYYY'
                                           mode="year" placeholder="选择年份"
                                           @panelChange="panelChangeOne"></a-date-picker>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
            <div class="nav-top-time">
                <span id="date">{{ date }}</span>
                <span id="time">{{ stime }}</span>

            </div>
        </div>
        <div id="streetMap"></div>
        <actual ref="actual" class="left-banner"/>
        <right-banner ref="rightBanner" class="right-banner"/>
        <center-modal ref="centerModal"></center-modal>
    </div>
</template>
<script>
import gcoord from 'gcoord'
import actual from './component/actual.vue'
import rightBanner from './component/rightBanner.vue'
import moment from 'moment'
import {queryMeteringCentersApi, queryRegionPolyLine} from '@/api/map'
import centerModal from './component/center'

export default {
    components: {actual, rightBanner, centerModal},
    data() {
        return {
            regionName: '',
            currentTime: 'month',
            moduleCode: this.$route.query.moduleCode,
            map: null,
            date: moment().format('YYYY-MM-DD'),
            stime: '',
            centerListData: [],
            time: [{key: 'day', name: '日'}, {key: 'month', name: '月'}, {key: 'year', name: '年'}],
            year: moment(),
            monthDate: moment(),
            dayDate: [moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().startOf('month').format("YYYY-MM-DD")]
        }
    },
    mounted() {
        this.$getCurrentModuleInfo(this.moduleCode).then(res => {
            this.regionName = res.regionName
        })
        this.createdMap()
        setInterval(() => {
            this.stime = moment().format('HH:mm:ss')
        }, 1000)
    },
    methods: {
        onChange(type, e) {
            let params = {}
            if (type === 'day') {
                params = {
                    time: 'day',
                    startTime: e[0],
                    endTime: e[1]
                }
            } else if (type == 'month') {
                params = {
                    time: 'month',
                    startTime: e
                }
            }
            this.$refs.actual.scrollList({...params})
            this.$refs.rightBanner.showChart({...params})
            this.$refs.rightBanner.renderType({...params})
        },
        panelChangeOne(value) {
            this.year = value
            let params = {time: 'year', startTime: this.year}
            this.$refs.actual.scrollList({...params})
            this.$refs.rightBanner.showChart({...params})
            this.$refs.rightBanner.renderType({...params})
        },
        showCenter(code) {
            this.$refs.centerModal.showModal(code)
        },
        handleChange(e) {
            this.currentTime = e
            let params = {}
            if (e == 'day') {
                params = {time: 'day', startTime: this.dayDate[0], endTime: this.dayDate[1]}
            } else if (e == 'month') {
                params = {time: 'month', startTime: this.monthDate}
            } else {
                params = {time: 'year', startTime: this.year}
            }
            this.$refs.actual.scrollList({...params})
            this.$refs.rightBanner.showChart({...params})
            this.$refs.rightBanner.renderType({...params})
        },
        async createdMap() {
            const url = 'https://api.map.baidu.com/api?v=1.0&type=webgl&ak=Ps3R7NeheYm1ozqBtQ1ouOZu9Ph6IFcr'
            const jsapi = document.createElement('script')
            jsapi.charset = 'utf-8'
            jsapi.src = url
            document.head.appendChild(jsapi)
            this.map = new BMapGL.Map('streetMap')
            var point = new BMapGL.Point(120.4808, 31.233534);
            this.map.centerAndZoom(point, 15);
            this.map.setTilt(50);
            this.map.enableScrollWheelZoom();
            var path = [];
            let r = await queryRegionPolyLine({moduleCode: this.$route.query.moduleCode})
            var tmp = r.result.plot
            for (var j = 0; j < tmp.length; j++) {
                if (tmp[j][0] && tmp[j][1]) {
                    var lng = tmp[j][0];
                    var lat = tmp[j][1];
                    let result = gcoord.transform([lng, lat], gcoord.GCJ02, gcoord.BD09)
                    path.push(new BMapGL.Point(result[0], result[1]));
                }
            }
            var prism = new BMapGL.Prism(path, 200, {
                topFillColor: '#5679ea',
                topFillOpacity: 0.5,
                sideFillColor: '#5679ea',
                sideFillOpacity: 0.9

            });
            this.map.addOverlay(prism);
            this.mapStyleSet()
            this.queryMeteringCenters()
            var navi3DCtrl = new BMapGL.NavigationControl3D();  // 添加3D控件
            this.map.addControl(navi3DCtrl);
            this.$refs.actual.scrollList({time: 'month', startTime: this.monthDate})
            this.$refs.rightBanner.showChart({time: 'month', startTime: this.monthDate})
            this.$refs.rightBanner.renderType({time: 'month', startTime: this.monthDate})
        },
        mapStyleSet() {
            var styleJson = [{
                "featureType": "land",
                "elementType": "geometry",
                "stylers": {
                    "color": "#242f3eff"
                }
            }, {
                "featureType": "manmade",
                "elementType": "geometry",
                "stylers": {
                    "color": "#242f3eff"
                }
            }, {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": {
                    "color": "#17263cff"
                }
            }, {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": {
                    "color": "#9e7d60ff"
                }
            }, {
                "featureType": "road",
                "elementType": "geometry.stroke",
                "stylers": {
                    "color": "#554631ff"
                }
            }, {
                "featureType": "districtlabel",
                "elementType": "labels.text.fill",
                "stylers": {
                    "color": "#d69563ff"
                }
            }, {
                "featureType": "districtlabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                    "color": "#17263cff",
                    "weight": 3
                }
            }, {
                "featureType": "poilabel",
                "elementType": "labels.text.fill",
                "stylers": {
                    "color": "#d69563ff"
                }
            }, {
                "featureType": "poilabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                    "color": "#17263cff",
                    "weight": 3
                }
            }, {
                "featureType": "subway",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "railway",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "poilabel",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "subwaylabel",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "subwaylabel",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "tertiarywaysign",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "tertiarywaysign",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "provincialwaysign",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "provincialwaysign",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "nationalwaysign",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "nationalwaysign",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "highwaysign",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "highwaysign",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "green",
                "elementType": "geometry",
                "stylers": {
                    "color": "#263b3eff"
                }
            }, {
                "featureType": "nationalwaysign",
                "elementType": "labels.text.fill",
                "stylers": {
                    "color": "#d0021bff"
                }
            }, {
                "featureType": "nationalwaysign",
                "elementType": "labels.text.stroke",
                "stylers": {
                    "color": "#ffffffff"
                }
            }, {
                "featureType": "city",
                "elementType": "labels",
                "stylers": {
                    "visibility": "on"
                }
            }, {
                "featureType": "city",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "city",
                "elementType": "labels.text.fill",
                "stylers": {
                    "color": "#d69563ff"
                }
            }, {
                "featureType": "city",
                "elementType": "labels.text.stroke",
                "stylers": {
                    "color": "#17263cff"
                }
            }, {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": {
                    "color": "#d69563ff"
                }
            }, {
                "featureType": "water",
                "elementType": "labels.text.stroke",
                "stylers": {
                    "color": "#242f3eff"
                }
            }, {
                "featureType": "local",
                "elementType": "geometry.fill",
                "stylers": {
                    "color": "#38414eff"
                }
            }, {
                "featureType": "local",
                "elementType": "geometry.stroke",
                "stylers": {
                    "color": "#ffffff00"
                }
            }, {
                "featureType": "fourlevelway",
                "elementType": "geometry.fill",
                "stylers": {
                    "color": "#38414eff"
                }
            }, {
                "featureType": "fourlevelway",
                "elementType": "geometry.stroke",
                "stylers": {
                    "color": "#ffffff00"
                }
            }, {
                "featureType": "tertiaryway",
                "elementType": "geometry.fill",
                "stylers": {
                    "color": "#38414eff"
                }
            }, {
                "featureType": "tertiaryway",
                "elementType": "geometry.stroke",
                "stylers": {
                    "color": "#ffffff00"
                }
            }, {
                "featureType": "tertiaryway",
                "elementType": "labels.text.fill",
                "stylers": {
                    "color": "#759879ff"
                }
            }, {
                "featureType": "fourlevelway",
                "elementType": "labels.text.fill",
                "stylers": {
                    "color": "#759879ff"
                }
            }, {
                "featureType": "highway",
                "elementType": "labels.text.fill",
                "stylers": {
                    "color": "#759879ff"
                }
            }, {
                "featureType": "highway",
                "elementType": "geometry.fill",
                "stylers": {
                    "color": "#9e7d60ff"
                }
            }, {
                "featureType": "highway",
                "elementType": "geometry.stroke",
                "stylers": {
                    "color": "#554631ff"
                }
            }, {
                "featureType": "provincialway",
                "elementType": "geometry.fill",
                "stylers": {
                    "color": "#9e7d60ff"
                }
            }, {
                "featureType": "provincialway",
                "elementType": "geometry.stroke",
                "stylers": {
                    "color": "#554631ff"
                }
            }, {
                "featureType": "tertiaryway",
                "elementType": "labels.text.stroke",
                "stylers": {
                    "color": "#1a2e1cff"
                }
            }, {
                "featureType": "fourlevelway",
                "elementType": "labels.text.stroke",
                "stylers": {
                    "color": "#1a2e1cff"
                }
            }, {
                "featureType": "highway",
                "elementType": "labels.text.stroke",
                "stylers": {
                    "color": "#1a2e1cff"
                }
            }, {
                "featureType": "nationalway",
                "elementType": "labels.text.stroke",
                "stylers": {
                    "color": "#1a2e1cff"
                }
            }, {
                "featureType": "nationalway",
                "elementType": "labels.text.fill",
                "stylers": {
                    "color": "#759879ff"
                }
            }, {
                "featureType": "nationalway",
                "elementType": "geometry.fill",
                "stylers": {
                    "color": "#9e7d60ff"
                }
            }, {
                "featureType": "nationalway",
                "elementType": "geometry.stroke",
                "stylers": {
                    "color": "#554631ff"
                }
            }, {
                "featureType": "cityhighway",
                "elementType": "geometry.fill",
                "stylers": {
                    "color": "#9e7d60ff"
                }
            }, {
                "featureType": "cityhighway",
                "elementType": "geometry.stroke",
                "stylers": {
                    "color": "#554631ff"
                }
            }, {
                "featureType": "arterial",
                "elementType": "geometry.fill",
                "stylers": {
                    "color": "#9e7d60ff"
                }
            }, {
                "featureType": "arterial",
                "elementType": "geometry.stroke",
                "stylers": {
                    "color": "#554631fa"
                }
            }, {
                "featureType": "medicallabel",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "medicallabel",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "entertainmentlabel",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "entertainmentlabel",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "estatelabel",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "estatelabel",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "businesstowerlabel",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "businesstowerlabel",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "companylabel",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "companylabel",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "governmentlabel",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "governmentlabel",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "restaurantlabel",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "restaurantlabel",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "hotellabel",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "hotellabel",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "shoppinglabel",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "shoppinglabel",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "lifeservicelabel",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "lifeservicelabel",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "carservicelabel",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "carservicelabel",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "financelabel",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "financelabel",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "otherlabel",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "otherlabel",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "airportlabel",
                "elementType": "labels",
                "stylers": {
                    "visibility": "on"
                }
            }, {
                "featureType": "airportlabel",
                "elementType": "labels.text.fill",
                "stylers": {
                    "color": "#d69563ff"
                }
            }, {
                "featureType": "airportlabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                    "color": "#17263cff"
                }
            }, {
                "featureType": "airportlabel",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "highway",
                "stylers": {
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "6"
                }
            }, {
                "featureType": "highway",
                "stylers": {
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "7"
                }
            }, {
                "featureType": "highway",
                "stylers": {
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "8"
                }
            }, {
                "featureType": "highway",
                "stylers": {
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "9"
                }
            }, {
                "featureType": "highway",
                "stylers": {
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "10"
                }
            }, {
                "featureType": "highway",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "6"
                }
            }, {
                "featureType": "highway",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "7"
                }
            }, {
                "featureType": "highway",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "8"
                }
            }, {
                "featureType": "highway",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "9"
                }
            }, {
                "featureType": "highway",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "10"
                }
            }, {
                "featureType": "nationalway",
                "stylers": {
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "6"
                }
            }, {
                "featureType": "nationalway",
                "stylers": {
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "7"
                }
            }, {
                "featureType": "nationalway",
                "stylers": {
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "8"
                }
            }, {
                "featureType": "nationalway",
                "stylers": {
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "9"
                }
            }, {
                "featureType": "nationalway",
                "stylers": {
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "10"
                }
            }, {
                "featureType": "nationalway",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "6"
                }
            }, {
                "featureType": "nationalway",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "7"
                }
            }, {
                "featureType": "nationalway",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "8"
                }
            }, {
                "featureType": "nationalway",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "9"
                }
            }, {
                "featureType": "nationalway",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "10"
                }
            }, {
                "featureType": "nationalway",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "6"
                }
            }, {
                "featureType": "nationalway",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "7"
                }
            }, {
                "featureType": "nationalway",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "8"
                }
            }, {
                "featureType": "nationalway",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "9"
                }
            }, {
                "featureType": "nationalway",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "10"
                }
            }, {
                "featureType": "highway",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "6"
                }
            }, {
                "featureType": "highway",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "7"
                }
            }, {
                "featureType": "highway",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "8"
                }
            }, {
                "featureType": "highway",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "9"
                }
            }, {
                "featureType": "highway",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "10"
                }
            }, {
                "featureType": "provincialway",
                "stylers": {
                    "curZoomRegionId": "0",
                    "curZoomRegion": "8,9",
                    "level": "8"
                }
            }, {
                "featureType": "provincialway",
                "stylers": {
                    "curZoomRegionId": "0",
                    "curZoomRegion": "8,9",
                    "level": "9"
                }
            }, {
                "featureType": "provincialway",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "8,9",
                    "level": "8"
                }
            }, {
                "featureType": "provincialway",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "8,9",
                    "level": "9"
                }
            }, {
                "featureType": "provincialway",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "8,9",
                    "level": "8"
                }
            }, {
                "featureType": "provincialway",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "8,9",
                    "level": "9"
                }
            }, {
                "featureType": "cityhighway",
                "stylers": {
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "6"
                }
            }, {
                "featureType": "cityhighway",
                "stylers": {
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "7"
                }
            }, {
                "featureType": "cityhighway",
                "stylers": {
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "8"
                }
            }, {
                "featureType": "cityhighway",
                "stylers": {
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "9"
                }
            }, {
                "featureType": "cityhighway",
                "stylers": {
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "10"
                }
            }, {
                "featureType": "cityhighway",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "6"
                }
            }, {
                "featureType": "cityhighway",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "7"
                }
            }, {
                "featureType": "cityhighway",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "8"
                }
            }, {
                "featureType": "cityhighway",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "9"
                }
            }, {
                "featureType": "cityhighway",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "10"
                }
            }, {
                "featureType": "cityhighway",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "6"
                }
            }, {
                "featureType": "cityhighway",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "7"
                }
            }, {
                "featureType": "cityhighway",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "8"
                }
            }, {
                "featureType": "cityhighway",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "9"
                }
            }, {
                "featureType": "cityhighway",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "6,10",
                    "level": "10"
                }
            }, {
                "featureType": "arterial",
                "stylers": {
                    "curZoomRegionId": "0",
                    "curZoomRegion": "9,10",
                    "level": "9"
                }
            }, {
                "featureType": "arterial",
                "stylers": {
                    "curZoomRegionId": "0",
                    "curZoomRegion": "9,10",
                    "level": "10"
                }
            }, {
                "featureType": "arterial",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "9,10",
                    "level": "9"
                }
            }, {
                "featureType": "arterial",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "9,10",
                    "level": "10"
                }
            }, {
                "featureType": "arterial",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "9,10",
                    "level": "9"
                }
            }, {
                "featureType": "arterial",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off",
                    "curZoomRegionId": "0",
                    "curZoomRegion": "9,10",
                    "level": "10"
                }
            }, {
                "featureType": "building",
                "elementType": "geometry.topfill",
                "stylers": {
                    "color": "#2a3341ff"
                }
            }, {
                "featureType": "building",
                "elementType": "geometry.sidefill",
                "stylers": {
                    "color": "#313b4cff"
                }
            }, {
                "featureType": "building",
                "elementType": "geometry.stroke",
                "stylers": {
                    "color": "#1a212eff"
                }
            }, {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": {
                    "color": "#759879ff"
                }
            }, {
                "featureType": "road",
                "elementType": "labels.text.stroke",
                "stylers": {
                    "color": "#1a2e1cff"
                }
            }, {
                "featureType": "provincialway",
                "elementType": "labels.text.fill",
                "stylers": {
                    "color": "#759879ff"
                }
            }, {
                "featureType": "cityhighway",
                "elementType": "labels.text.fill",
                "stylers": {
                    "color": "#759879ff"
                }
            }, {
                "featureType": "arterial",
                "elementType": "labels.text.fill",
                "stylers": {
                    "color": "#759879ff"
                }
            }, {
                "featureType": "provincialway",
                "elementType": "labels.text.stroke",
                "stylers": {
                    "color": "#1a2e1cff"
                }
            }, {
                "featureType": "cityhighway",
                "elementType": "labels.text.stroke",
                "stylers": {
                    "color": "#1a2e1cff"
                }
            }, {
                "featureType": "arterial",
                "elementType": "labels.text.stroke",
                "stylers": {
                    "color": "#1a2e1cff"
                }
            }, {
                "featureType": "local",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "manmade",
                "elementType": "labels.text.fill",
                "stylers": {
                    "color": "#d69563ff"
                }
            }, {
                "featureType": "manmade",
                "elementType": "labels.text.stroke",
                "stylers": {
                    "color": "#17263cff"
                }
            }, {
                "featureType": "subwaystation",
                "elementType": "geometry",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "transportationlabel",
                "elementType": "labels.icon",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "transportationlabel",
                "elementType": "labels",
                "stylers": {
                    "visibility": "off"
                }
            }, {
                "featureType": "estate",
                "elementType": "geometry",
                "stylers": {
                    "color": "#2a3341ff"
                }
            }];
            this.map.setMapStyleV2({styleJson: styleJson});
        },
        queryMeteringCenters() {
            queryMeteringCentersApi({}, this.$route.query.moduleCode).then(res => {
                var hasDataIcon = new BMapGL.Icon("https://zhongshi-garbage.oss-cn-shanghai.aliyuncs.com/screen/20211011105047.png", new BMapGL.Size(30, 26));
                var noDataIcon = new BMapGL.Icon("https://zhongshi-garbage.oss-cn-shanghai.aliyuncs.com/screen/20211011105041.png", new BMapGL.Size(30, 26));
                this.centerListData = res.result
                this.centerListData.forEach(item => {
                    if (item.longitude && item.latitude) {
                        let result = gcoord.transform([item.longitude, item.latitude], gcoord.GCJ02, gcoord.BD09)
                        // let marker = new BMapGL.Marker(new BMapGL.Point(item.longitude,item.latitude), {
                        let marker = new BMapGL.Marker(new BMapGL.Point(result[0], result[1]), {

                            icon: item.hasData ? hasDataIcon : noDataIcon
                        });
                        // 在地图上添加点标记
                        this.map.addOverlay(marker);
                        // let opts = {
                        //     width: 200,
                        //     height: 100,
                        //     title: item.centerName
                        // };
                        var opts1 = {
                            position: new BMapGL.Point(result[0], result[1]), // 指定文本标注所在的地理位置
                            offset: new BMapGL.Size(30, -10) // 设置文本偏移量
                        };
                        var label = new BMapGL.Label(`<div style="border: none;background: #9e7d60;padding-left: 10px;padding-right: 10px;">${item.centerName}</div>`, opts1);
                        // 自定义文本标注样式
                        label.setStyle({
                            borderRadius: '5px',
                            color: 'white',
                            border: 'none',
                            fontSize: '16px',
                            height: '30px',
                            lineHeight: '30px',
                            fontFamily: '微软雅黑',
                            padding: '0',
                            display: 'flex',
                            flexDirection: 'row',

                        });
                        this.map.addOverlay(label);
                        // let infoWindow = new BMapGL.InfoWindow(`${item.longitude}${item.latitude}`, opts);
                        // 点标记添加点击事件
                        let _this = this
                        label.addEventListener('click', function (e) {
                            // this.map.openInfoWindow(infoWindow, new BMapGL.Point(item.longitude,item.latitude)); // 开启信息窗口
                            _this.$refs.centerModal.showModal(item.centerCode)
                        });
                    }
                })
            })
        }
    },
}
</script>

<style lang="scss">
@import '@/assets/variableCss.scss';

.data-visual-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  // background: #17263c;
  // .head_nav_banner{height:3vw; background: url(../../assets/image/head_bg.png) no-repeat center center; background-size: 100% 100%; position: relative}
  .head_nav_banner {
    position: fixed;
    top: 0;
    left: 0;
    height: 80px;
    width: 100%;
    z-index: 9;
    background-color: rgba(3, 21, 24, 0.7);
    text-align: center;

    .nav-top-title {
      line-height: 80px;
      color: #ffffff;
      font-size: 24px;
      font-weight: 600;
    }

    .nav-top-time {
      position: absolute;
      top: 0;
      right: 10px;
      padding: 20px;
    }

    .nav-top-time span {
      display: block;
      color: #ffffff;
      line-height: 20px;
    }
  }

  .left-banner {
    position: absolute;
    top: 5vw;
    left: 1vw;
    width: 27%;
    height: 40%;
    border: 1px solid #9e7d60;
    background-color: rgba(3, 21, 24, 0.7);
    z-index: 99;
  }

  .right-banner {
    position: absolute;
    top: 5vw;
    right: 1vw;
    width: 27%;
    // height: 30%;
    // border: 1px solid #9e7d60;
    // background: #17263c;
  }
}

@media screen and (max-width: 1000px) {
  body {
    //  height:100%;
  }
}

#streetMap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  font-family: "微软雅黑";
}
</style>