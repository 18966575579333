<template>
    <div>
        <type ref="type"/>
        <trend ref="trend"/>
       

    </div>
</template>

<script>
import type from './type.vue'
import trend from './trend.vue'
var second= 30;
var t;
export default {
    components: { type, trend},
    data() {
        return {
            isTime: false,
            chartParam: '',
            typeParam: ''
        }
    },
    watch: {
        isTime(n, o) {
            if(n == true) {
                this.$refs.trend.drawCityChart({...this.chartParam})
                this.$refs.type.queryMeteringType({...this.typeParam})
                this.isTime = false
                // t = setInterval(() => {
                //     this.$refs.trend.drawCityChart({...this.chartParam})
                //     this.$refs.type.queryMeteringType({...this.typeParam})
                //     this.isTime = false
                // }, second * 1000)
            } else {
                // clearInterval(t)
                setTimeout(() => {
                    this.isTime = true
                }, second * 1000)
            }
       
        }
    },
    methods: {
        showChart(params){
            this.chartParam = params
            this.isTime = true
            // this.$refs.trend.drawCityChart({...params})
            // setInterval(() => {
            //     this.$refs.trend.drawCityChart({...params})
            // }, 30000)
        },

        renderType(params) {
            this.typeParam = params
            // this.$refs.type.queryMeteringType({...params})
            // setInterval(() => {
            //    this.$refs.type.queryMeteringType({...params})
            // }, 30000)
        }
    }
}
</script>

<style lang="scss">

</style>