<template>
  <div class="areaMap">
    <a-card  class="center-part-card">
        <div id="centerContainer"></div>
    </a-card>
  </div>
</template>

<script>
import { queryMeteringCenterDetailPartApi  } from '@/api/map'
import { Line } from '@antv/g2plot';
export default {
    data() {
        return{
            regionName: '',
            current: 0,
            cityChart: '',
        }
    },
    methods: {
        changeTime(params) {
            this.drawCityChart(params)
        },
        drawCityChart(params) {
            queryMeteringCenterDetailPartApi({...params}, this.$route.query.moduleCode).then(res => {
                if (this.cityChart) {
                    this.cityChart.update({
                        data: res.result,
                    })
                    return
                }
                this.cityChart = new Line('centerContainer', {
                    autoFit: true,
                    data: res.result,
                    xField: 'day',
                    yField: 'weight',
                    smooth: true,
                    height: 230,
                    yAxis: {
                        label: {
                        // 数值格式化为千分位
                        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
                        },
                    },
                    legend: {
                        itemName: {
                        style: {
                            fill: '#fff',
                        },
                        },
                    },
                    seriesField: 'trash',
                    color: ({ trash }) => {
                        return trash === '厨余垃圾' ? '#707070' : trash === '其他垃圾' ? '#0081ff' :  trash === '可回收物' ? '#39b54a' : '#d81e06';
                    },
                });

                this.cityChart.render();
            })
        },
    }
}
</script>

<style lang="scss">
.center-part-card{
    width: 100%;
    margin-bottom:10px;
    background:rgba(3, 21, 24, 0.7);
    max-height: 300px;
}
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: rgba(3, 21, 24, 0.7);
  overflow: hidden;
}
.ant-carousel .slick-dots-bottom{
    bottom: -15px;
}
.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.ant-carousel .slick-dots li button{
    background: black;
}
.ant-carousel .slick-dots li.slick-active button{
    background: red;
}
        .ant-card-bordered {
        border-color: #132230 !important;
    }
    .ant-card-head{
        color: white;
        border-color: #132230;
    }
    .ant-card-body{
        color: white;
    }
</style>