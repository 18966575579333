<template>
    <div class="item">
        <div class="title">实时数据</div>
        <div :class="[isScroll ? 'scroll-content' : 'no-scroll-content']">
            <div class="scroll-list" id="slist">
              
            </div>
        </div>

    </div>
</template>
<script>
var scrollList;
var second= 30;
var t;
import { getTodayRealApi } from '@/api/today'
export default {
    data() {
        return{
            isScroll: false,
            data: [],
            isTime: false
        }
    },
    watch: {
        data(n, o) {
            if(n.length > 6) {
                this.isScroll = true
            }
        },
        isTime(n, o) {
            if(n == true) {
                this.getData()
                // t = setInterval(() => {
                // this.getData()
                // }, second * 1000)
            } else {
                // clearInterval(t)
                setTimeout(() => {
                    this.isTime = true
                }, second * 1000)
            }
       
        }
    },
    methods: {
        scrollList() {
            scrollList = document.getElementById('slist')
            this.isTime = true
            // this.getData()
            // t = setInterval(() => {
            //   this.getData()
            // }, second * 1000)
         
        },
        getData() {
              getTodayRealApi({moduleCode: this.$route.query.moduleCode}).then(res => {
                    this.data = res.result
                    res.result.forEach(item => {
                        var row = document.createElement('div')
                        row.style.width = '100%'
                        row.style.display='flex'
                        row.style.flexDirection = 'row'
                        row.style.justifyContent = 'space-between'
                        row.innerHTML += '<div class="line-item">' + item.centerName +'</div><div class="line-item">'+ item.typeName +'</div><div class="line-item">'+ item.weight +'kg</div><div class="line-item">'+ item.collectTime +'</div>'
                        scrollList.appendChild(row)
                    })
                    this.isTime = false
                })
        }
    }
}
</script>

<style lang="scss">
.item{
    width: 100%;
    height: 100%;
    padding: 10px;
    position: relative;
}
.title{
    position: absolute;
    background: #9e7d60;
    font-size: 1vw;
    color: #fff;
    top: 0;
    left: 0;
    padding: 4px;
    border-radius: 0 0 10px;
}
.line{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    line-height: 2vw;
}
.line-item{
    width: 25%;
    // text-align: center;
    line-height: 2vw;
}
// .line-item:nth-child(4){
//     width: 32%;
//     text-align: center;
//     line-height: 2vw;
// }
.scroll-content{
	width: 100%;
    height: 90%;
    margin-top: 2vw;
	position: relative;
	overflow: hidden;
  
}
.no-scroll-content{
	width: 100%;
    height: 90%;
    margin-top: 2vw;
	position: relative;
	overflow: hidden;
  
}
.scroll-content>.scroll-list{
	position: absolute;
	top: 0;
	left: 2.5%;
	width: 95%;
	animation: scroll 60s linear infinite normal;
}
.no-scroll-content>.scroll-list{
    position: absolute;
	top: 0;
	left: 2.5%;
	width: 95%;
	// animation: scroll 12s linear infinite normal;
}
.scroll-content>.scroll-list>div{
	width: 100%;
    // height: 2vw;
    color: #fff;
    font-size: 0.8vw;
    line-height: 2vw;
}
.no-scroll-content>.scroll-list>div{
	width: 100%;
    // height: 2vw;
    color: #fff;
    font-size: 0.8vw;
    line-height: 2vw;
}
.scroll-content>.scroll-list>div:nth-child(2n){
	// background: #9e7d60;
    //  background-image: linear-gradient(to right, #9e7d60 , #17263c);
}
.no-scroll-content>.scroll-list>div:nth-child(2n){
	// background: #9e7d60;
    //  background-image: linear-gradient(to right, #9e7d60 , #17263c);
}
 @keyframes scroll {
     0% {
        top: 0;
        }
        100% {
            /* 需要滚动内容的总高度 */
            top: -300px;
        }
    }
</style>