<template>
     <div class="item" style="margin-top: 1vw;">
        <div class="title">街道四分类趋势</div>
        <div id="trendContainer"></div>

    </div>
</template>

<script>
import { queryMeteringCenterDetailPartApi  } from '@/api/map'
import { Line } from '@antv/g2plot';
export default {
    data() {
        return {
            cityChart: '',
        }
    },
    methods: {
           drawCityChart(params) {
            queryMeteringCenterDetailPartApi({...params}, this.$route.query.moduleCode).then(res => {
                if (this.cityChart) {
                    this.cityChart.update({
                        data: res.result,
                    })
                    return
                }
                this.cityChart = new Line('trendContainer', {
                    autoFit: true,
                    data: res.result,
                    xField: 'day',
                    yField: 'weight',
                    smooth: true,
                    height: 230,
                    yAxis: {
                        label: {
                        // 数值格式化为千分位
                        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
                        },
                    },
                    legend: {
                        itemName: {
                        style: {
                            fill: '#fff',
                        },
                        },
                    },
                    seriesField: 'trash',
                    color: ({ trash }) => {
                        return trash === '厨余垃圾' ? '#707070' : trash === '其他垃圾' ? '#0081ff' :  trash === '可回收物' ? '#39b54a' : '#d81e06';
                    },
                });

                this.cityChart.render();
            })
        },
    }
}
</script>

<style lang="scss">
.item{
    width: 100%;
    // height: 14vw;
    padding: 10px;
    position: relative;
    border: 1px solid #9e7d60;
   background-color: rgba(3, 21, 24, 0.7)
    
}
.title{
    position: absolute;
    background: #9e7d60;
    font-size: 1vw;
    color: #fff;
    top: 0;
    left: 0;
    padding: 4px;
    border-radius: 0 0 10px;
}
#trendContainer{
    margin-top: 2vw;
}
</style>