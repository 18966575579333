<template>
     <div class="item">
        <div class="title">街道四分类汇总</div>
        <a-row :gutter="20" style="margin-top: 2vw;color:#fff;">
           <a-col :span="12" style="" class="city-type-col" v-for="(item, index) in result" :key="index">
                <img
                    alt="example"
                    :src="images[index]"
                    style="width: 26%;"
                />
                <div style="display: flex;flex-direction: column;justify-content: space-around;align-items: center;">
                    <p>
                        <span style="font-size: 17px;"> {{item.weight | formatWeight}}</span>
                        <br/>同比： {{item.chainComparison}}%
                        <br/> 环比： {{item.yearToYear}}%
                    </p>
                </div>
            </a-col>
        </a-row>

    </div>
</template>

<script>
import { queryMeteringTypeApi } from '@/api/map'

const images = [
    'https://yihaoclub.oss-cn-shanghai.aliyuncs.com/image/cook.svg',
    'https://yihaoclub.oss-cn-shanghai.aliyuncs.com/image/other.svg',
    'https://yihaoclub.oss-cn-shanghai.aliyuncs.com/image/recycle.svg',
    'https://yihaoclub.oss-cn-shanghai.aliyuncs.com/image/danger.svg'
]
export default {
    data() {
        return {
            images,
            result: null
        }
    },
    filters: {
        formatWeight(val) {
            if(val > 100000) {
                return (val / 1000).toFixed(2) + 'T'
            }
            return val.toFixed(2) + 'KG'
        }
    },
    methods: {
        queryMeteringType(parameters) {
            queryMeteringTypeApi({...parameters}, this.$route.query.moduleCode).then(res => {
                this.result = res.result
            })
        }
    }
}
</script>

<style lang="scss">
.item{
    width: 100%;
    // height: 100%;
    padding: 10px;
    position: relative;
    // height: 14vw;
    border: 1px solid #9e7d60;
    background-color: rgba(3, 21, 24, 0.7)
}
.title{
    position: absolute;
    background: #9e7d60;
    font-size: 1vw;
    color: #fff;
    top: 0;
    left: 0;
    padding: 4px;
    border-radius: 0 0 10px;
}
.city-type-col{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
        }
</style>