<template>
  <div class="areaMap">
    <a-card  class="center-type-card">
        <a-row :gutter="20" >
            <a-col :span="6"  class="trash-col" v-for="(item, index) in result" :key="index">
                <img
                    alt="example"
                    :src="images[index]"
                    style="width: 26%;"
                />
                <div  class="trash-content">
                    <p>
                        <span style="font-size: 17px;"> {{item.weight | formatWeight}}</span>
                        <br/>同比： {{item.chainComparison}}%
                        <br/> 环比： {{item.yearToYear}}%
                    </p>
                </div>
            </a-col>
        </a-row>
    </a-card>
  </div>
</template>

<script>
import { queryMeteringCenterDetailTypeApi,} from '@/api/map'
const images = [
    'https://yihaoclub.oss-cn-shanghai.aliyuncs.com/image/cook.svg',
    'https://yihaoclub.oss-cn-shanghai.aliyuncs.com/image/other.svg',
    'https://yihaoclub.oss-cn-shanghai.aliyuncs.com/image/recycle.svg',
    'https://yihaoclub.oss-cn-shanghai.aliyuncs.com/image/danger.svg'
]
export default {
    data() {
        return{
            images,
            regionName: '',
            result: []
        }
    },
    filters: {
        formatWeight(val) {
            if(val > 100000) {
                return (val / 1000).toFixed(2) + 'T'
            }
            return val.toFixed(2) + 'KG'
        }
    },
    mounted() {
        this.regionName = this.$route.query.regionName
    },
    methods: {
        queryMeteringCenterDetailType(params) {
            queryMeteringCenterDetailTypeApi({...params}, this.$route.query.moduleCode).then(res => {
                this.result = res.result.typeList
            })
        }
    }
}
</script>

<style lang="scss">
.areaMap{
    .center-type-card{
        width: 100%;
        position:relative;
        background:rgba(3, 21, 24, 0.7);
        .ant-card-bordered {
            border-color: rgba(3, 21, 24, 0.7) !important;
        }
        .ant-card-head{
            color: white;
            border-color: #132230;
        }
        .ant-card-body{
            color: white;
        }
        h3{
            color:#fff;
        }
        .trash-col{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            .trash-content{
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
            }
        }
    }
}
</style>