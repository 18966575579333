import service from './network';
export function getTodayReceivedApi(data){
    return service.request({
        url: '/screen/area/today/type',
        method: 'get',
        params: data
    })
}

export function getTodayReceivedPerHourApi(data){
    return service.request({
        url: '/screen/area/perHour',
        method: 'get',
        params: data
    })
}

export function getTodayStreetApi(data){
    return service.request({
        url: '/screen/area/street',
        method: 'get',
        params: data
    })
}

export function getTodayRealApi(data){
    return service.request({
        url: '/screen/area/real',
        method: 'get',
        params: data
    })
}

export function getTypeDataApi(data){
    return service.request({
        url: '/screen/area/type/today',
        method: 'get',
        params: data
    })
}

export function getTypeMonthApi(data){
    return service.request({
        url: '/screen/area/nearlyMonth',
        method: 'get',
        params: data
    })
}