<template>
    <div class="center-modal">
        <a-modal v-model="visible" :footer="null" width="1000px" title="小区详情" class="center-modal">
            <a-descriptions >
                <a-descriptions-item label="小区编号">
                    {{centerInfo.centerCode}}
                </a-descriptions-item>
                <a-descriptions-item label="小区名称">
                    {{centerInfo.centerName}}
                </a-descriptions-item>
                <a-descriptions-item label="设备数量">
                    {{centerInfo.deviceCode}}
                </a-descriptions-item>
                <a-descriptions-item :label="`设备${index + 1}`" v-for="(item, index) in centerInfo.deviceList" :key="index">
                    {{item.deviceCode}}
                </a-descriptions-item>
                <a-descriptions-item label="地址">
                    {{centerInfo.address}}
                </a-descriptions-item>
            </a-descriptions>
            <a-space>
                <a-select :default-value="currentTime" style="width: 120px" @change="handleChange">
                    <a-select-option :value="item.key" v-for="(item, index) in time" :key="index">
                        {{item.name}}
                    </a-select-option>
                </a-select>
                <a-range-picker @change="onChange('day', $event)"  v-if="currentTime == 'day'"  :default-value="dayDate"/>
                <a-month-picker placeholder="选择月份" @change="onChange('month', $event)" v-if="currentTime == 'month'" :default-value="monthDate"/>
                <a-date-picker v-if="currentTime == 'year'" mode="year" placeholder="选择年份"  @panelChange="panelChangeOne" v-model="year" format='YYYY' ></a-date-picker>
            </a-space>
            <center-type ref="centerType"></center-type>
            <center-part ref="centerPart"/>
        </a-modal>
    </div>
</template>

<script>
import centerPart from './centerPart'
import moment from 'moment'
import centerType from './centerType'
import { queryMeteringCenterDetailApi } from '@/api/map'
export default {
    components: {
        centerType,
        centerPart
    },
    data() {
        return {
            centerCode: '',
            visible: false,
            centerInfo: '',
            time: [{key: 'day', name: '日'},{key: 'month', name: '月'}, {key: 'year', name: '年'}],
            currentTime: 'month',
            year: moment(),
            monthDate: moment(),
            dayDate: [moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),moment().startOf('month').format("YYYY-MM-DD")]
        }
    },
    methods: {
        showModal(code){
            this.visible = true
            this.centerCode = code
            this.queryMeteringCenterDetail()
            setTimeout(() => {
                this.$refs.centerType.queryMeteringCenterDetailType({centerCode: this.centerCode,time: 'month', startTime: this.monthDate})
                this.$refs.centerPart.drawCityChart({centerCode: this.centerCode, time: 'month', startTime:this.monthDate})
            }, 200)
        },
        queryMeteringCenterDetail() {
            queryMeteringCenterDetailApi({centerCode: this.centerCode}, this.$route.query.moduleCode).then(res => {
                this.centerInfo = res.result
            })
        },
        onChange(type, e) {
            let params = {}
            if(type === 'day') {
                params = {
                    time: 'day', 
                    startTime: e[0], 
                    endTime: e[1]
                }
            } else if(type == 'month') {
               params = {
                   time: 'month',
                   startTime: e
               }
            }
            params.centerCode = this.centerCode
            this.$refs.centerType.queryMeteringCenterDetailType(params)
            this.$refs.centerPart.drawCityChart(params)
        },
        handleChange(e) {
            this.currentTime = e
            let params = {}
            if(e == 'day') {
                params = {time: 'day', startTime: this.dayDate[0], endTime: this.dayDate[1]}
            } else if(e =='month') {
                params = {time: 'month', startTime: this.monthDate}
            }  else {
                params = {time: 'year', startTime: this.year}
            }
            params.centerCode = this.centerCode
            this.$refs.centerType.queryMeteringCenterDetailType(params)
            this.$refs.centerPart.drawCityChart(params)
        },
        panelChangeOne(value) {
            this.year = value
            let params = {time: 'year', startTime: this.year}
            params.centerCode = this.centerCode
            this.$refs.centerType.queryMeteringCenterDetailType(params)
            this.$refs.centerPart.drawCityChart(params)
        },
    }
}
</script>

<style lang="scss">
.center-modal{
    .ant-modal-title{
        color: white;
    }
    .ant-modal-content{
        background-color: rgba(3, 21, 24, 1) !important;
    }
    .ant-modal-header{
        background-color: #9e7d60 !important;
        color: white !important;
        border-color: rgba(5, 15, 20, 1) !important;
    }
    .ant-descriptions-item-label{
        color: white !important;
    }
    .ant-descriptions-item-content{
        color: white !important;
    }
}

</style>